
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";
import CloudActivationError from "@/components/landingPages/cloudActivation/CloudActivationError.vue";
import CloudActivationFlow from "@/components/landingPages/cloudActivation/CloudActivationFlow.vue";
import { CloudActivationData, CloudActivationProps } from "@/types/localtypes";
import { CloudActivationStatus, CloudActivationType } from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "CloudActivation",
  components: { CloudActivationBackground, CloudActivationError, CloudActivationFlow },
})
export default class CloudActivation extends Vue {
  hasInternalError = false;
  intervalCheckStatus!: number;
  currentStatus!: CloudActivationStatus;
  isDelayed: boolean = false;
  isReady: boolean = false;
  internalElapsedTime = 0;
  mounted() {
    if (!this.hasError) {
      this.currentStatus = this.initialActivationStatus;
      this.initStatusCheck();
    }
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("fxCol");
    }
    return classes;
  }

  initStatusCheck() {
    this.handleNewStatus(this.currentStatus);
    if (this.canCheckStatus()) {
      this.refreshIntervalCheck();
    }
  }

  get delayBetweenCheck(): 5 | 20 {
    return this.activationType === "REGULAR" ? 20 : 5;
  }

  refreshIntervalCheck() {
    if (this.intervalCheckStatus) {
      clearInterval(this.intervalCheckStatus);
    }
    this.intervalCheckStatus = setInterval(() => {
      this.checkStatus();
    }, this.delayBetweenCheck * 1000);
  }

  canCheckStatus() {
    switch (this.currentStatus) {
      case "IN_PROGRESS":
      case "DELAYED":
        return true;
      case "READY":
      default:
        return false;
    }
  }

  async checkStatus() {
    if (!this.canCheckStatus()) {
      return;
    }
    try {
      this.incrementElapsedTime();
      const statusResponse = await this.$jfCloudActivations.getStatus({
        serverName: this.serverName,
        token: this.token,
      });
      this.handleNewStatus(statusResponse.activationStatus);
    } catch (e) {
      this.$log.error(e);
      this.hasInternalError = true;
      clearInterval(this.intervalCheckStatus);
    }
  }

  handleNewStatus(newStatus: CloudActivationStatus) {
    this.currentStatus = newStatus;
    switch (newStatus) {
      case "READY":
        clearInterval(this.intervalCheckStatus);
        this.isReady = true;
        this.isDelayed = false;
        break;
      case "DELAYED":
        this.isDelayed = true;
        break;
    }
  }

  get flowDataProps(): CloudActivationProps {
    return {
      isReady: this.isReady,
      isDelayed: this.isDelayed,
      elapsedTime: this.elapsedTime,
      serverName: this.serverName,
      instanceUrl: this.instanceUrl,
      cloudProvider: this.cloudProvider,
      regionName: this.regionName,
      products: this.products,
      showContentNodes: !this.products.includes("connect"),
      hasConnect: this.products.includes("connect"),
      ssoType: this.ssoType,
      platformRegistrationLink: "",
    };
  }

  incrementElapsedTime() {
    this.$jfCloudActivations.incrementElapsedTime(this.delayBetweenCheck);
    this.internalElapsedTime += this.delayBetweenCheck;
  }

  get elapsedTime() {
    return this.initialElapsedTime + this.internalElapsedTime;
  }

  get inError() {
    return this.hasError || this.hasInternalError;
  }

  get serverName(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.serverName) || "-";
  }
  get products(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.products) || "-";
  }

  get hasConnect(): boolean {
    return this.products.includes("connect");
  }

  get cloudProvider(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.cloudProviderCode) || "-";
  }

  get regionName(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.regionName) || "-";
  }

  get instanceUrl(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.instanceUrl) || "";
  }
  get token(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.token) || "";
  }
  get codeAlreadyUsed() {
    return this.cloudActivationData.data && this.cloudActivationData.data.codeAlreadyUsed;
  }
  get initialActivationStatus(): CloudActivationStatus {
    return (this.cloudActivationData.data && this.cloudActivationData.data.activationStatus) || "IN_PROGRESS";
  }
  get initialElapsedTime(): number {
    return (this.cloudActivationData.data && this.cloudActivationData.data.elapsedTime) || 0;
  }
  get activationType(): CloudActivationType {
    return (this.cloudActivationData.data && this.cloudActivationData.data.activationType) || "REGULAR";
  }
  get ssoType(): string {
    return (this.cloudActivationData.data && this.cloudActivationData.data.ssoType) || "";
  }
  get hasError() {
    return this.cloudActivationData.hasError;
  }

  get cloudActivationData(): CloudActivationData {
    return this.$jfCloudActivations.getCloudActivationData() as CloudActivationData;
  }
}
