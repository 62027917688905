
declare var PLATFORM_DOMAIN: any;
declare var CONNECT_DOMAIN: any;
import CircleSpinnerToCheckmark from "@/components/spinners/CircleSpinnerToCheckmark.vue";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { CloudActivationProps } from "@/types/localtypes";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Alert, ContentNode } from "@/types/layout/contentNode";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";

@Component({
  name: "CloudActivationFlow",
  components: {
    CircleSpinnerToCheckmark,
    FadeSpinner,
    SimpleIconTitle,
    SimpleAlert,
    ProgressBar,
  },
})
export default class CloudActivationFlow extends Vue {
  @Prop() private serverName!: CloudActivationProps["serverName"];
  @Prop() private cloudProvider!: CloudActivationProps["cloudProvider"];
  @Prop() private regionName!: CloudActivationProps["regionName"];
  @Prop() private instanceUrl!: CloudActivationProps["instanceUrl"];
  @Prop() private showContentNodes!: CloudActivationProps["showContentNodes"];
  @Prop() private products!: CloudActivationProps["products"];
  @Prop() private hasConnect!: CloudActivationProps["hasConnect"];
  @Prop({ default: false })
  private isReady!: CloudActivationProps["isReady"];
  @Prop({ default: false })
  private isDelayed!: CloudActivationProps["isDelayed"];
  @Prop({ default: 0 })
  private elapsedTime!: CloudActivationProps["elapsedTime"];
  @Prop() private ssoType!: CloudActivationProps["ssoType"];

  displayMeta = false;
  domLoaded = false;
  progressBarPercent: number = 0;
  progressBarMessage = "* This can take up to a minute...";
  minSecondsBeforeRedirect: number = 60;
  delaySeconds: number = this.minSecondsBeforeRedirect / 100;
  activationCode = "";
  serverData: Array<ContentNode> = [
    {
      title: "Server Name",
      value: this.serverName,
      icon: "activation_server.svg",
    },
    {
      title: "Location",
      value: this.cloudProvider + " in " + this.regionName,
      icon: "activation_location.svg",
    },
  ];
  boxesData: Array<ContentNode> = [
    {
      title: "Automated Security Scanning",
      value: "Enable DevSecOps with OSS security scanning",
      icon: "activation_scan.svg",
    },
    {
      title: "Personalized Repositories",
      value: "Personalized Repositories will be created for you",
      icon: "activation_repo.svg",
    },
    {
      title: "CI/CD Pipelines",
      value: "Automate your CI/CD pipeline from code to production",
      icon: "activation_pipelines.svg",
    },
  ];
  alertData: Alert = {
    message: "Activation is taking longer than expected, an email will be sent once the environment is ready.",
    type: "warn",
  };

  mounted() {
    this.setProgressBarInitialValue();
    setTimeout(() => {
      this.domLoaded = true;
    }, 0);
    this.increaseProgressBarPercent();
  }

  setProgressBarInitialValue() {
    let query = window.location.search;
    query = query.substring(query.indexOf("ac=") + 3);
    this.activationCode = query;
    let progressBarInitial = localStorage.getItem(query);
    if (progressBarInitial && parseInt(progressBarInitial) > 0) {
      this.progressBarPercent = parseInt(progressBarInitial);
    } else {
      this.progressBarPercent = 0;
    }
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  get titleText() {
    let text = "DevOps";
    if (this.products.includes("connect")) {
      text = "IoT";
    }
    return `Ready for your ${text} journey?`;
  }

  get subtitleText() {
    return "Please hang on while we create your environment";
  }

  get showAlert() {
    return this.isDelayed || (this.progressBarPercent > 89 && !this.isReady);
  }

  goToPlatform() {
    let url = this.instanceUrl || `https://${this.serverName}.${PLATFORM_DOMAIN}`;
    url += "/ui/login";

    if (this.products.includes("connect")) {
      window.location.href = `${CONNECT_DOMAIN}/jfrog_registration_redirect?organization_name=${this.serverName}.${PLATFORM_DOMAIN}`;
    } else {
      if (url.indexOf("?") > -1) {
        url += "&firstRedirect=true";
      } else {
        url += "?firstRedirect=true";
      }

      if (this.ssoType) {
        url += "&auth_method=" + this.ssoType;
      }

      window.location.href = url;
    }
  }

  increaseProgressBarPercent() {
    if (this.progressBarPercent > 99 && this.isReady) {
      this.goToPlatform();
    }
    if (this.progressBarPercent < 90 && !this.isDelayed) {
      this.progressBarPercent++;
    }
    if (this.isReady) {
      this.progressBarPercent += 20;
    }
    if (this.progressBarPercent > 100) {
      this.progressBarPercent = 100;
    }
    localStorage.setItem(this.activationCode, this.progressBarPercent.toString());
    setTimeout(() => {
      this.increaseProgressBarPercent();
    }, this.delaySeconds * 1000);
  }
}
